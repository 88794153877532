<template>

  <div>

    <aplicaciones-list/>

  </div>



</template>

<script>

import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AplicacionesList from '@/modules/ejecutante/views/catalogos/aplicaciones/AplicacionesList.vue'


export default {
  name: 'Aplicaciones',
  data(){
    return {
      perPageOptions: [10,25,50,100],
      perPage: null,
      searchQuery: null,

    }
  },
  components: {
    AplicacionesList,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,


    vSelect
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
