<template>

  <div>

    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>


    <template v-else>

      <!-- empty state: no existen datos-->
      <template v-if="isEmptyList">

        <b-card class="text-center">

          <feather-icon
              icon="InboxIcon"
              size="100"
              class="mr-50 mb-5"
          />
          <h2>No hay sistemas para mostrar</h2>
          <p class="p-2">
            Actualmente no hay sistemas para mostrar. <br>
            Si necesita agregar nuevos sistemas, haga clic en el botón de abajo para crear una nuevo sistema.
          </p>

          <aplicacion-nuevo/>


        </b-card>

      </template>

      <!-- sección de datos -->
      <template v-else>

      <b-card>


        <b-row>

          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <label>Mostrar</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>
          <!-- Buscar -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
              />

              <aplicacion-nuevo/>

            </div>
          </b-col>


        </b-row>


        <div class="m-2">

          <b-table
              class="position-relative"
              :items="filteredData"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              responsive
              show-empty
          >

            <template #cell(sysNombre)="data">
              <router-link :to="{name: 'catalogo-aplicaciones-view', query: {uuid: data.item.sysUuid} }" >
            <span>
              {{ data.item.sysNombre }}
            </span>
              </router-link>
            </template>

            <template #cell(sysEstado)="data">
              <b-badge
                  pill
                  :variant="getLightStatusVariant( data.item.sysEstado )"
                  class="text-capitalize"
              >
                {{ getStatusName( data.item.sysEstado) }}
              </b-badge>
            </template>


          </b-table>


        </div>


        <!-- pagination -->
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ totalRecords }} registros encontrados</span>
            </b-col>
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRecords"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>
          </b-row>

        </div>



      </b-card>

      </template>
    </template>


  </div>


</template>

<script>


import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BPagination

  } from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import AplicacionNuevo from '@/modules/ejecutante/views/catalogos/aplicaciones/AplicacionNuevo.vue'
import { resolveLightStatusVariant, resolvePerPageOptions, resolveStatusName } from '@/modules/common/utils'

export default {
  name: 'AplicacionesList',
  components: {
    AplicacionNuevo,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,

    vSelect
  },

  data(){
    return {

      isLoading: false,

      perPageOptions: resolvePerPageOptions(),
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      searchQuery: '',

      fields: [
        { key: 'sysNombre', label: 'Nombre', sortable: true },
        { key: 'sysDescripcion', label: 'Descripción', sortable: true },
        { key: 'sysEstado', label: 'Estado', sortable: true },

      ],

      items: []

    }
  },

  computed:{
    ...mapGetters('aplicacion-module', ['fetchSystems']),

    filteredData(){
      const data = this.fetchSystems( this.searchQuery )
      this.totalRecords = data.length
      return data
    },

    // verificar que existan datos originales (antes de filtros)
    isEmptyList(){
      return this.fetchSystems().length === 0
    }

  },

  methods: {

    ...mapActions('aplicacion-module', ['getSistemas']),

    getLightStatusVariant( status ){
      return resolveLightStatusVariant( status )
    },

    getStatusName( status ) {
      return resolveStatusName( status )
    },

  },

  async created() {

    await this.getSistemas()

    this.items = this.fetchSystems(  )

  }

}
</script>
