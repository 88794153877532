<template>

  <div>
    <b-button
        variant="primary"
        @click="showSidebar=true"
    >
      <span class="text-nowrap">Agregar Aplicación</span>
    </b-button>

  <b-sidebar
      v-model="showSidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >

    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Agregar Aplicación
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>
      <b-form
          class="p-2"
      >
        <!-- Nombre -->
        <b-form-group
            label="Nombre"
            label-for="nombre"
        >
          <b-form-input
              id="nombre"
              maxlength="100"
              autofocus
              trim
              placeholder="Nombre de la Aplicación"
              v-model="aplicacion.sysNombre"
          />
        </b-form-group>
        <!-- Descripción -->
        <b-form-group
            label="Descripción"
            label-for="descripcion"
        >
          <b-form-textarea
              id="descripcion"
              autofocus
              trim
              placeholder="Descripcion de la Aplicación"
              v-model="aplicacion.sysDescripcion"
          />
        </b-form-group>
        <!-- Link -->
        <b-form-group
            label="Link"
            label-for="link"
        >
          <b-form-input
              id="link"
              maxlength="200"
              autofocus
              trim
              placeholder="URL de la Aplicación"
              v-model="aplicacion.sysLink"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button

              variant="primary"
              class="mr-2"
              :disabled="isBusy || hasSomeNullEmptyItem"
              @click.prevent="handleSubmit"
          >
            <template v-if="isBusy">
              <b-spinner small ></b-spinner>
              Guardando...
            </template>
            <template v-else >
              Guardar
            </template>

          </b-button>
          <b-button

              type="button"
              variant="outline-secondary"
              @click="hide"
              :disabled="isBusy"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>

  </b-sidebar>


  </div>


</template>

<script>

import {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/modules/auth/utils'

import {
  mapActions
} from 'vuex'

export default {
  name: 'AplicacionNuevo',
  components:{
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner,
    BFormTextarea,
    ToastificationContent
  },
  data(){
    return {
      showSidebar: false,
      isBusy: false,
      aplicacion: {
        'sysNombre': null,
        'sysDescripcion': null,
        'sysLink': null
      }
    }
  },

  computed: {
    // verificar si algún elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {

      const nullable = ( element ) => element === null || element == ''
      return Object.values( this.aplicacion ).some( nullable )

    },
  },

  methods: {
    ...mapActions( 'aplicacion-module', [ 'createAplicacion' ] ),
    async handleSubmit(){
      try {
        this.errors = []
        this.isBusy = true
        const user = getUserData()

        const formData = {
          ...this.aplicacion,
          userId: user.id
        }

        await this.createAplicacion(formData)



        this.isBusy = false
        this.showSidebar = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notificación',
            icon: 'CheckIcon',
            text: 'Registro guardado correctamente.',
            variant: 'success',
          },
        })

        this.aplicacion.sysNombre = null
        this.aplicacion.sysDescripcion = null
        this.aplicacion.sysEstado = null
        this.aplicacion.sysLink = null

      }catch (e) {

        // errores de autenticacion
        if ( error.response.status == 422 ) {

          const  data  = error.response.data.errors

          console.log( "UNXPECTED", error.response.data )

          // errores de validacion
          this.errors = data

        }

      }  finally {
            this.isBusy = false
      }
    },

    // procesar peticion de descartar
    handleDiscard(){
      this.showSidebar = false
    },

    // reset los campos del formulario
    resetForm(){
      Object.keys(this.aplicacion).forEach( key =>  this.aplicacion[ key ] = null )
    }

  }

}
</script>

<style scoped>

</style>
